:root {
  --alharablack: black;
  --globalMarginValue: 80px;
  --contentWrapperWidth: 40%;
}

body {
  background-color: black;
  /* margin: var(--globalMarginValue); */
}

#contentWrapper {
  margin: 0px var(--globalMarginValue) 0px var(--globalMarginValue);
  display: grid;
}

img {
  height: auto;
  display: block;
  width: 100%;
}

@media screen and (min-width: 1700px) {
  :root {
    --contentWrapperWidth: 25%;
  }
}


@media screen and (max-width: 1200px) {
  :root {
    --globalMarginValue: 40px;
    --contentWrapperWidth: 65%;
  }
}

@media screen and (max-width: 667px) {
  :root {
    --contentWrapperWidth: 100%;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --globalMarginValue: 10px;
    --contentWrapperWidth: 100%;
  }
}
