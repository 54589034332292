:root {
    --video-width: 100vw;
    --video-height: 100vh;
  }
  @media (min-aspect-ratio: 16/9) {
    :root {
      --video-height: 56.25vw;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    :root {
      --video-width: 177.78vh;
    }
  }


.backgroundDiv{
    z-index: -1000;
    height: 100% !important;
    width: 100% !important;
    position:fixed;
    top:0px;
    bottom:0px;
    overflow: hidden;
}

.reactVideoPlayerCoverUp{
    z-index: -999;
    /* background:rgba(255, 0, 0, 0.39); */
    position:fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
}

.backgroundFile {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.reactVideoPlayerComponent{
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%; 

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: var(--video-width);
    height: var(--video-height);

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#backgroundImage {
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}




