iframe > html > body.body_widget_discussion {
    background: rgba(0, 0, 0, 0) !important;
}

[id^='telegram-discussion'] {
    margin-top: -40px;
    margin-left: 0px;
    /* margin-left: 52px !important; */
    margin-bottom: var(--globalMarginValue);
    width: var(--contentWrapperWidth);
    display: block;
    z-index: 100 !important;
    background-color: transparent; 
    /* border: 1px solid white !important; */
    border-radius: 5px;
    min-width: 300px !important;
}

#Chat {
    overflow: hidden;
    /* padding: var(--globalMarginValue);*/
}


body.dark.body_widget_discussion{
    background: rgba(0, 0, 0, 0) !important;

}

@media screen and (max-width: 1200px) {
    [id^='telegram-discussion'] {
        /* width: 75%; */
        /* margin-left: 47px !important; */ 
    }
}

@media screen and (max-width: 667px) {
    [id^='telegram-discussion'] {
        /* width: 100%; */
        /* margin-left: 0px !important; */
    }
}

@media screen and (max-width: 400px) {
    [id^='telegram-discussion'] {
        margin-bottom: 0px;
        /* margin-left: 0px !important; */
        margin-top: -40px;
    }
}
